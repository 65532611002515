body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img.main-logo {
  width: 145px;
}

.masthead {
  /* height: 100vh; */
  min-height: 390px;
  /* max-height: 550px; */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.home-image{
  min-height: 430px;
  max-height: 530px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('./images/bg-home-spaceman-and-dog.svg');
  margin-bottom: 10px;
  background-size: contain;
}

.pageHeader {
  height: 100vh;
  min-height: 200px;
  max-height: 250px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.dashboard-background {
  background-image: linear-gradient(rgba(0, 0, 0, 0.527),rgba(0, 0, 0, 0.5)), url('./images/dashboard-banner.png');
}

.bottombar-design{
  background-color: transparent;
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  margin-top: 30px !important;
}
@media (min-width:992px) { /* laptops and desktops */ 
  .borderRight{
    border-right: 1px solid #2b2b2b;
    height: 250px;
  }
} 

p{
  margin-top: 0;
  margin-bottom: 0rem !important;
}

.inlineDisplay {
  white-space: nowrap;
  display:inline  
}

.brand-logo {
  width: 140px !important;
  margin-top: 20px !important;
}
.text-right{
  text-align: end;
}

.chartColumn{
  width: 30%;
}
.dropdown-input .dropdown {
  display: block;
  width: 100%;
}
.dropdown-input button.btn {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  width: 90px;
  margin-top: -9px;
}
.dropdown-input .dropdown-menu.show {
  width: 100%;
}
.dropdown-input input:focus-visible, .dropdown-input button:focus {
  outline: none;
  box-shadow: none;
}
.card{
  width: 100%;
}
.faround {
  display: inline-block;
  border-radius: 60px;
  box-shadow: 0px 0px 6px #888;
  padding: 0.4em 0.4em;
}
.navbar{
  padding-top: 2px !important;
}
.mr-4{
  margin-right: 1.5rem;
}
.ml-4{
  margin-left: 1.5rem;
}

.compound-card{
  width: 100%;
  padding: 2% 0%;
  padding-left: 4%;
  border-radius: 5px;
  border: 1px solid #eee;
  margin-bottom: 2.5%;
  align-items: center;
}

.compound-range-slider{
  height: 5px;
  background: #63CCE0;
  box-shadow: none;
}

.compound-range-slider .rangeslider__handle{
  width: 20px;
  height: 20px;
  box-shadow: none;
  background: #63CCE0;
  border: none;
}

.compound-range-slider .rangeslider__handle:after {
  content: ' ';
  position: relative;
}

.compound-range-slider .rangeslider__fill{
  background: #63CCE0;
}