@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@300;500&family=Poppins:wght@300;400;500&display=swap');
.navbar {
  /* padding-left: 65px; */
}
@media only screen and (max-width: 990px) {
  .mt-sm-25 {
    margin-top: 25px !important;
  }
  .navbar {
    padding-left: 0px;
  }
}
